<template>
  <div class="createGroup">
    <el-form :model="groupData" :rules="rules" ref="ruleForm" label-width="100px">
      <el-form-item label="群聊名称" prop="name">
        <el-input v-model="groupData.name" placeholder="请输入群聊名称" />
      </el-form-item>
      <el-form-item label="选择好友" prop="memberList">
        <el-cascader v-model="groupData.memberList" :props="props" style="width: 100%" filterable></el-cascader>
      </el-form-item>
    </el-form>
    <div class="text-center">
      <el-button type="primary" @click="confirmCreate">创建</el-button>
      <el-button @click="closeDialog">取消</el-button>
    </div>
  </div>
</template>

<script>
import { adminList, schoolAdminList, teacherList } from '@/api/public'
import { createGroup } from '../imTool'

export default {
  name: 'CreateGroup',
  data() {
    return {
      props: {
        multiple: true,
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(node, 'node')
          if (level === 0) {
            resolve([
              { value: 'zx_gly', label: '总校管理员', leaf: false },
              { value: 'xq_gly', label: '校区管理员', leaf: false },
              { value: 'js', label: '联系人(教师)', leaf: false }
            ])
          } else if (level === 1) {
            // 权宜之计，设置一个足够大的分页来保证可以获取到所有数据
            // 工期太着急不想弄了
            const searchInfo = {
              page: 1,
              page_size: 999999
            }
            if (node.value === 'zx_gly') {
              adminList(searchInfo).then(res => {
                const newList = res.data.data.map(item => {
                  return { value: item.im_id, label: item.username, leaf: true }
                })
                resolve(newList)
              })
            } else if (node.value === 'xq_gly') {
              schoolAdminList(searchInfo).then(res => {
                const newList = res.data.data.map(item => {
                  return { value: item.im_id, label: item.username, leaf: true }
                })
                resolve(newList)
              })
            } else if (node.value === 'js') {
              teacherList(searchInfo).then(res => {
                const newList = res.data.data.map(item => {
                  return { value: item.im_id, label: item.username, leaf: true }
                })
                resolve(newList)
              })
            }
          } else {
            return resolve()
          }
        }
      },
      groupData: {
        name: '',
        memberList: []
      },
      rules: {
        name: [{ required: true, message: '请输入群组名称', trigger: 'blur' }],
        memberList: [{ type: 'array', required: true, message: '请至少选择一个群组成员', trigger: 'change' }]
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    },
    // 创建
    confirmCreate() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          const memberList = this.groupData.memberList.map(item => {
            return { userID: item[1] }
          })
          createGroup({ name: this.groupData.name, memberList: memberList }).then(() => { // 创建成功
            this.$message.success('创建成功！')
          }).catch(function(imError) {
            this.$message.error('创建失败，请稍候重试！')
            console.warn('createGroup error:', imError); // 创建群组失败的相关信息
          });
        }
      })
    }
  }
}
</script>

<style lang=scss>
.createGroup {
}
</style>
