<template>
  <div id="app">
    <im />
  </div>
</template>

<script>
import im from './components/im'

export default {
  name: 'App',
  components: {
    im
  }
}
</script>

