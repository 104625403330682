/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

// 数字秒转时分秒
export function formatSeconds(value) {
  const result = parseInt(value)
  const h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
  const m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60))
  const s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60))

  let res = ''
  if (h !== '00') res += `${h}小时`
  if (m !== '00') res += `${m}分钟`
  res += `${s}秒`
  return res
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

/**
 * @obj {Object} 需要深拷贝的对象
 * @returns {Object}
 *  */
export function deepClone(obj, exclude) {
  if (!isObject(obj)) {
    throw new Error('obj 不是一个对象！')
  }
  const isArray = Array.isArray(obj)
  const cloneObj = isArray ? [] : {}
  for (const key in obj) {
    if (!exclude || exclude.indexOf(key) === -1) {
      cloneObj[key] = isObject(obj[key]) ? deepClone(obj[key], exclude) : obj[key]
    }
  }
  return cloneObj
}

// 判断是否为对象
function isObject(o) {
  return (typeof o === 'object' || typeof o === 'function') && o !== null
}

/** 复制文本到剪贴板
 * @param {string} url 需要复制的url
 * @returns {Promise}
 */
export function copyToClipboard(url) {
  return new Promise((resolve, reject) => {
    // 数字没有 .length 不能执行selectText 需要转化成字符串
    const textString = String(url) // 复制的内容
    let input = document.querySelector('#copy-input')
    if (!input) {
      input = document.createElement('textarea')
      input.id = 'copy-input'
      input.readOnly = 'readOnly' // 防止ios聚焦触发键盘事件
      input.style.border = 'none'
      input.style.width = '0'
      input.style.height = '0'
      input.style.overflow = 'hidden'
      document.body.appendChild(input)
    }
    input.value = textString
    // ios必须先选中文字且不支持 input.select();
    selectText(input, 0, textString.length)
    if (document.execCommand('copy')) {
      document.execCommand('copy')
      resolve()
    } else {
      reject('浏览器不支持复制！')
    }
    input.blur()
    document.body.removeChild(input)
  })
}

function selectText(textbox, startIndex, stopIndex) {
  if (textbox.createTextRange) { // ie
    const range = textbox.createTextRange()
    range.collapse(true)
    range.moveStart('character', startIndex) // 起始光标
    range.moveEnd('character', stopIndex - startIndex) // 结束光标
    range.select() // 不兼容苹果
  } else { // firefox/chrome
    textbox.setSelectionRange(startIndex, stopIndex)
    textbox.focus()
  }
}

/** 表单验证
 * @formData { Object } 需要校验的表单
 * @rules { Object } 校验规则，例如
 * {name: [
 *    {associatedKey: 'type', associatedValue: ['value1', 'value2' ...]}, // 关联性校验:检测key为type的值存在，校验字段是否不为空，value可以限制到具体的值
 *    { required: true, message: '请输入姓名' }, // 必填校验
 *    { regular: /(^1[2-9][0-9]{9}$)|(^\d{3,4}-?\d{7,8}$)/, message: '请输入正确的联系方式！' } // 正则校验
 * ]}
 *  */
export function checkForm(formData, rules) {
  for (const item in rules) {
    for (let i = 0; i < rules[item].length; i++) {
      if (rules[item][i].required && !formData[item]) {
        return rules[item][i].message
      } else if (rules[item][i].regular && !rules[item][i].regular.test(formData[item])) {
        return rules[item][i].message
      } else if (rules[item][i].associatedKey) {
        // 关联性
        // 有具体关联值时，关联值成了时，被校验值不能为空！
        if (rules[item][i].associatedValue) {
          if (rules[item][i].associatedValue.indexOf(formData[rules[item][i].associatedKey]) !== -1 && !formData[item]) {
            return rules[item][i].message
          }
        } else if (!formData[item]) {
          return rules[item][i].message
        }
      }
    }
  }
  return null
}

/** 时间转换，将秒转换为n小时n分n秒格式
 * @seconds {Number} 需要转换的秒数
 * @returns {String} 转换结果字符串
 */
export function formatSecond(secondNum) {
  const hours = Math.floor(secondNum / 3600) + '小时'
  const minutes = Math.floor(secondNum / 60) + '分'
  const seconds = secondNum % 60 + '秒'
  return `${hours}${minutes}${seconds}`
}
