import request from '@/utils/request'

// 获取用户信息
export function getUserInfo() {
  return request({
    url: 'user',
    method: 'get'
  })
}

// 获取用户信息
export function adminList(data) {
  return request({
    url: 'contactor/admin',
    method: 'get',
    param: data
  })
}

// 获取用户信息
export function schoolAdminList(data) {
  return request({
    url: 'contactor/school',
    method: 'get',
    param: data
  })
}

// 获取用户信息
export function teacherList(data) {
  return request({
    url: 'contactor/teacher',
    method: 'get',
    param: data
  })
}
