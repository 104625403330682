import TIM from 'tim-js-sdk';
import TIMUploadPlugin from 'tim-upload-plugin';

let userData = {}
let timReady = false

let options = {
  SDKAppID: 1400580152 // 接入时需要将 0 替换为您的云通信应用的 SDKAppID，类型为 Number
};
// 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
let tim = TIM.create(options); // SDK 实例通常用 tim 表示

// 设置 SDK 日志输出级别，详细分级请参见 setLogLevel 接口的说明
tim.setLogLevel(1); // 普通级别，日志量较多，接入时建议使用
// tim.setLogLevel(1); // release级别，SDK 输出关键信息，生产环境时建议使用

// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin });

// 检测tim状态
tim.on(TIM.EVENT.SDK_READY, () => {
  timReady = true
})
tim.on(TIM.EVENT.SDK_NOT_READY, () => {
  timReady = false
  // 重新登录
  login()
});

export async function eventListener(fun) {
  await waitReady();
  tim.on(TIM.EVENT.MESSAGE_RECEIVED, fun);
}


// 函数调用前确保IM已经进入完成状态
function waitReady() {
  return new Promise(resolve => {
    if (timReady) {
      resolve()
    } else {
      console.log('wait ready')
      const interval = setInterval(() => {
        if (timReady) {
          clearInterval(interval)
          resolve()
        }
      }, 100)
    }
  })
}

// 登录
export function login(data = userData) {
  userData = data
  return tim.login({ userID: data.userID, userSig: data.userSig })
}

// 登出
export async function logout() {
  await waitReady();
  return tim.logout()
}

// 销毁实例
export async function destroy() {
  await waitReady();
  return tim.destroy()
}

// 获取全量的会话列表
export async function getConversationList() {
  await waitReady()
  return tim.getConversationList()
}

// 拉取回话消息列表
export async function getMessageList(data) {
  await waitReady()
  return tim.getMessageList(data)
}

// 拉取群组列表
export async function getGroupList() {
  await waitReady()
  return tim.getGroupList()
}

// 创建群组
export async function createGroup(data) {
  console.log(data, 'data')
  await waitReady()
  return tim.createGroup({
    type: TIM.TYPES.GRP_WORK,
    name: data.name,
    memberList: data.memberList
  })
}

// 发送消息
export async function sendMessage(option) {
  const {toId, message, file, type = 'text', conversationType} = option
  await waitReady()
  return new Promise(resolve => {
    let createMessage
    switch (type) {
      case 'text': {
        createMessage = tim.createTextMessage({
          to: toId,
          conversationType: conversationType === 'C2C' ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP,
          payload: {
            text: message
          }
        })
        console.log(createMessage, 'createMessage1')
        break
      }
      case 'file': {
        createMessage = tim.createFileMessage({
          to: toId,
          conversationType: conversationType === 'C2C' ? TIM.TYPES.CONV_C2C : TIM.TYPES.CONV_GROUP,
          payload: {
            file: file,
          },
          onProgress: function(event) {
            console.log('file uploading:', event)
          }
        })
        break
      }
    }
    console.log(createMessage, 'createMessage')
    tim.sendMessage(createMessage).then(() => {
      resolve(createMessage)
    }).catch(imError => {
      console.log(imError)
    })
  })
}
