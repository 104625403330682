<template>
  <div class="searchPlugin">
    <span class="iconfont icon-search"></span>
    <input v-model="keyword" type="text" placeholder="搜索" @keydown.enter="confirm">
  </div>
</template>

<script>
export default {
  name: 'SearchPlugin',
  data() {
    return {
      keyword: ''
    }
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.keyword)
    }
  }
}
</script>

<style lang=scss>
.searchPlugin {
  width: 270px;
  height: 40px;
  background-color: #303841;
  border-radius: 15px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 24px;
    margin-right: 20px;
    color: #6A7681;
  }

  > input {
    background: transparent;
    border: none;
    outline: none;
    width: 200px;
    font-size: 20px;
    color: #ddd;
  }
}
</style>
