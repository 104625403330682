<template>
  <div v-if="loadMoreStatus !== 'nomore'" class="p-2 mx-4 bg-gray-500 rounded text-gray-200 flex justify-center cursor-pointer" @click.stop="loadMore(true)">
    <p class="loading-rotate" v-if="loadMoreStatus === 'loading'">
      <span class="iconfont icon-loading" />
    </p>
    <span v-if="loadMoreStatus === 'loadmore'">加载更多...</span>
    <span v-if="loadMoreStatus === 'nomore'">没有更多了</span>
    <span v-if="loadMoreStatus === 'loading'">加载中</span>
  </div>
</template>

<script>
export default {
  name: 'index',
  model: {
    event: 'loadMore',
    prop: 'loadMoreStatus'
  },
  props: {
    loadMoreStatus: {
      type: String,
      default: 'loadmore'
    }
  },
  methods: {
    loadMore() {
      if (this.loadMoreStatus === 'loading' || this.loadMoreStatus === 'nomore') {
        return
      }
      this.$emit('loadMore', 'loading')
    }
  }
}
</script>

<style lang=scss>
.index {
}
</style>
