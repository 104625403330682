<template>
  <div class="groupList">
    <div v-if="!showGroupList.length" class="text-center text-gray-400 py-4">暂无内容...</div>
    <div
      class="flex items-center px-6 py-4 cursor-default"
      :class="{'user-message-active': openMessage.conversationID === item.conversationID}"
      v-for="item in showGroupList"
      :key="item.id"
      @click="handleOpenMessage(item)"
    >
      <img class="w-12 h-12 rounded-full" :src="item | getAvatar" alt="">
      <div class="ml-6 flex-auto">
        <div class="flex items-center justify-between">
          <span class="text-xl text-white">{{ item.name }}</span>
          <span class="text-gray-400">{{ item.lastMessage.lastTime | getTime }}</span>
        </div>
        <div class="mt-2 text-gray-500">{{ item.lastMessage.messageForShow }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/utils'

export default {
  name: 'groupList',
  props: {
    groupList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      openMessage: {
        conversationID: ''
      }
    }
  },
  computed: {
    showGroupList() {
      return this.groupList.filter(conversation => {
        // 暂时过滤系统消息
        return conversation.type !== '@TIM#SYSTEM'
      })
    }
  },
  filters: {
    getName(conversation) {
      switch (conversation.type) {
        case 'GROUP': {
          return conversation.remark || conversation.groupProfile.name || conversation.userProfile.groupID
        }
        case 'C2C': {
          return conversation.remark || conversation.userProfile.nick || conversation.userProfile.userID
        }
      }
    },
    getTime(time) {
      return formatTime(time)
    },
    getAvatar(item) {
      if (item.type === 'C2C') {
        return item.userProfile.avatar
      } else {
        return require('../../../assets/default-avatar.png')
      }
    }
  },
  methods: {
    handleOpenMessage(item) {
      this.openMessage.conversationID = item.conversationID
      this.$emit('selectGroup', item)
    }
  }
}
</script>

<style lang=scss>
.groupList {

  .user-message-active {
    background-color: #404953;
  }
}
</style>
