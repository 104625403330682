<template>
  <div class="messageList" id="messageList">
    <load-more class="mb-6" v-model="loadMoreStatus" @loadMore="loadMoreMessage"/>
    <div class="grid grid-cols-1 auto-rows-auto gap-y-6">
      <template v-for="(message, index) in messageList">
        <div class="time-separator" :key="`time-${index}`">
          <span class="time">{{ message.time | getTime }}</span>
        </div>
        <div
          class="message-item"
          :class="{'place-self-start': message.flow === 'in', 'place-self-end': message.flow === 'out'}"
          :key="`message${index}`"
        >
          <!-- 发送消息 -->
          <template v-if="message.flow === 'out'">
            <div v-if="message.type === 'TIMFileElem'" class="message-info mr-4 text-center">
              <span class="iconfont icon-folder-filling text-6xl text-yellow-500 cursor-pointer" title="点击下载" @click="downloadFile(message.payload)"></span>
              <p class="text-xs text-gray-500 cursor-default" :title="message.payload.fileName">{{ message.payload.fileName | getShowFileName }}</p>
            </div>
            <div v-else-if="message.type === 'TIMTextElem'" class="message-info mr-4"
                 v-html="message.payload.text.replace(/\#[\u0065-\u0090\u4E00-\u9FA5]{1,3}\;/gi, emotion)"/>
          </template>
          <img v-if="message.avatar" class="w-12 h-12 rounded-full object-cover" :src="message.avatar" alt="">
          <img v-else class="w-10 h-10 rounded-full object-cover" src="../../../assets/default-avatar.png" alt="">
          <!-- 接收消息 -->
          <template v-if="message.flow === 'in'">
            <div v-if="message.type === 'TIMFileElem'">
              <span class="iconfont icon-folder-filling text-6xl text-yellow-500 cursor-pointer" title="点击下载"></span>
              <p>{{ message.payload.fileName }}</p>
            </div>
            <div v-else-if="message.type === 'TIMTextElem'" class="message-info ml-4"
                 v-html="message.payload.text.replace(/\#[\u0065-\u0090\u4E00-\u9FA5]{1,3}\;/gi, emotion)"/>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import LoadMore from '@/components/loadMore'

export default {
  name: 'MessageList',
  components: {
    LoadMore
  },
  props: {
    messageList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  filters: {
    getTime(time) {
      return formatTime(time)
    },
    getShowFileName(data) {
      return `${data.substr(0, 6)}*****${data.substr(data.length - 6, 6)}`
    }
  },
  data() {
    return {
      loadMoreStatus: 'loadmore'
    }
  },
  watch: {
    'messageList': {
      handler: function(newVal, oldVal) {
        this.loadMoreStatus = 'loadmore'
        const ele = document.getElementById('messageList');
        if (!document.getElementById('messageList')) {
          return
        }
        if (ele.scrollHeight - ele.scrollTop <= ele.offsetHeight + 40 || oldVal.length === 0) {
          this.$nextTick(() => {
            // 有新消息时滑动到屏幕底部
            ele.scrollTop = ele.scrollHeight;
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 将匹配结果替换表情图片
    emotion(res) {
      let word = res.replace(/#|;/gi, '')
      const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
      let index = list.indexOf(word)
      return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" style="display: inline">`
    },
    // 获取更多历史消息
    loadMoreMessage() {
      this.$emit('loadMoreMessage')
    },
    // 下载文件
    downloadFile(info) {
      const aLink = document.createElement('a')
      aLink.href = info.fileUrl
      aLink.download = info.fileName
      let event
      if (window.MouseEvent) {
        event = new MouseEvent('click')
      } else {
        event = document.createEvent('MouseEvents')
        event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
      }
      aLink.dispatchEvent(event)
    }
  }
}
</script>

<style lang=scss>
.messageList {
  height: inherit;
  overflow-y: auto;
  padding: 20px 35px;

  .message-item {
    display: flex;
    max-width: calc(100% - 200px);

    .message-info {
      background-color: #fff;
      border-radius: 10px;
      padding: 8px 20px;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
    /*height: 4px;*/
  }
}
</style>
