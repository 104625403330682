import axios from 'axios'
import config from '@/config'
import { getToken } from '@/utils/auth'

let assessmentAppid
let httpRequest

function createHttpRequest() {
  httpRequest = axios.create({
    baseURL: config.BASE_URL,
    timeout: 1000 * 30,
    headers: {
      'Content-Type': 'application/json',
      'token': getToken(),
      'useType': 1
    }
  })

  httpRequest.interceptors.response.use(function (response) {
    // 响应头和响应结果均为200，说明请求成功，其余情况，说明请求失败
    if (response.data.code === 200) {
      return response.data
    } else {
      alert(response.data.message)
      return Promise.reject(response.data)
    }
  }, function (error) {
    alert('网络请求失败，请稍后重试！')
    return Promise.reject(error)
  })
}

function request(data) {
  // commonjs机制会导致实例初始化后内部值不改变，需要检测到token变化后，重新初始化
  if (assessmentAppid !== getToken() || !httpRequest) {
    assessmentAppid = getToken()
    createHttpRequest()
  }
  const {url, method = 'get', param} = data
  return httpRequest({
    url: url,
    method: method,
    data: method.toLowerCase() === 'post' || method.toLowerCase() === 'put' ? param : '',
    params: method.toLowerCase() !== 'post' && method.toLowerCase() !== 'put' ? param : ''
  })
}

export default request
