<template>
  <div class="userGroup">
    <div
      class="group-item"
      v-for="group in groupList"
      :key="`gropu${group.id}`"
      @click="selectGroup(group)"
    >
      <div class="group-item-title" :class="{ 'group-item-active': activeGroup.id === group.id }">
        <span>{{ group.title }}</span>
        <span v-if="activeGroup.id === group.id" class="iconfont icon-arrow-down-bold"></span>
        <span v-else class="iconfont icon-arrow-right-bold"></span>
      </div>
      <div v-if="activeGroup.id === group.id">
        <div v-if="!group.contactsList.length" class="text-center text-gray-400 py-4">暂无内容...</div>
        <div
          class="flex items-center px-6 py-4 cursor-default hover:bg-gray-700"
          :class="{ 'user-message-active': activeGroup.selectContact === item.id }"
          v-for="item in group.contactsList "
          :key="item.id"
          @click.stop="selectContact(item)"
        >
          <img v-if="item.avatar" class="w-12 h-12 rounded-full object-cover" :src="item.avatar" alt="">
          <img v-else class="w-12 h-12 rounded-full object-cover" src="../../../assets/default-avatar.png" alt="">
          <div class="ml-6 flex-auto">
            <div class="flex items-center justify-between">
              <span class="text-xl text-white">{{ item.username }}</span>
            </div>
          </div>
        </div>
        <load-more v-model="loadMoreStatus" @loadMore="getList(true)" />
      </div>
    </div>
  </div>
</template>

<script>
import { adminList, schoolAdminList, teacherList } from '@/api/public'
import LoadMore from '@/components/loadMore'

export default {
  name: 'UserGroup',
  components: {
    LoadMore
  },
  data() {
    return {
      groupList: [
        {
          id: 1,
          title: '总校管理员',
          contactsList: []
        },
        {
          id: 2,
          title: '校区管理员',
          contactsList: []
        },
        {
          id: 3,
          title: '教师列表',
          contactsList: []
        }
      ],
      activeGroup: {
        id: null,
        group: null,
        selectContact: null
      },
      searchInfo: {
        page: 1,
        page_size: 15,
        keyword: ''
      },
      loadMoreStatus: 'loadmore' // loadmore.可以加载更多 nomore.没有更多了 loading.加载中
    }
  },
  created() {
    this.selectGroup(this.groupList[0])
  },
  mounted() {
  },
  methods: {
    // 选择分组
    selectGroup(group) {
      if (this.activeGroup.id === group.id) {
        this.activeGroup.id = null
        this.activeGroup.group = null
        return
      }
      this.activeGroup.id = group.id
      this.activeGroup.group = group

      this.getList()
    },
    // 加载更多的联系人
    handleSearch(data) {
      this.loadMoreStatus = 'loadmore'
      this.searchInfo.keyword = data
      this.getList()
    },
    // 获取联系人列表
    getList(loadMore = false) {
      if (loadMore) {
        this.searchInfo.page += 1
      } else {
        this.searchInfo.page = 1
      }
      switch (this.activeGroup.id) {
        case 1: {
          adminList(this.searchInfo).then(res => {
            if (loadMore) {
              this.activeGroup.group.contactsList = this.activeGroup.group.contactsList.concat(res.data.data)
            } else {
              this.activeGroup.group.contactsList = res.data.data
            }
            if (res.data.next_page_url) {
              this.loadMoreStatus = 'loadmore'
            } else {
              this.loadMoreStatus = 'nomore'
            }
          })
          break
        }
        case 2: {
          schoolAdminList(this.searchInfo).then(res => {
            if (loadMore) {
              this.activeGroup.group.contactsList = this.activeGroup.group.contactsList.concat(res.data.data)
            } else {
              this.activeGroup.group.contactsList = res.data.data
            }
            if (res.data.next_page_url) {
              this.loadMoreStatus = 'loadmore'
            } else {
              this.loadMoreStatus = 'nomore'
            }
          })
          break
        }
        case 3: {
          teacherList(this.searchInfo).then(res => {
            if (loadMore) {
              this.activeGroup.group.contactsList = this.activeGroup.group.contactsList.concat(res.data.data)
            } else {
              this.activeGroup.group.contactsList = res.data.data
            }
            if (res.data.next_page_url) {
              this.loadMoreStatus = 'loadmore'
            } else {
              this.loadMoreStatus = 'nomore'
            }
          })
          break
        }
      }
    },
    // 选择联系人
    selectContact(contact) {
      this.activeGroup.selectContact = contact.id
      this.$emit('selectContact', contact)
    }
  }
}
</script>

<style lang=scss>
.userGroup {

  .group-item {

    .group-item-title {
      width: 100%;
      height: 60px;
      line-height: 60px;
      color: #e0e0e0;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      user-select: none;

      &:hover {
        background-color: #404953;
      }
    }
  }

  .group-item-active {
    background-color: #404953;
  }

  .user-message-active {
    background-color: #4c576b;

    &:hover {
      background-color: #576276;
   }
  }
}
</style>
