<template>
  <div class="im flex">
    <div class="list-classify">
      <div class="info">
        <img v-if="info.avatar" class="avatar" :src="info.avatar" alt="">
        <img v-else class="avatar" src="../../assets/default-avatar.png" alt="">
        <div class="name">{{info.username}}</div>
      </div>
      <div
        class="classify-item"
        :class="{ 'classify-item-active' : classifyActive === item.value }"
        v-for="item in classifyList"
        :key="`classify-${item.value}`"
        @click="changeClassify(item)"
      >{{ item.label }}
      </div>
    </div>
    <div class="list-classify-item">
      <div class="search-title">
        <search-plugin @confirm="handleSearch"/>
        <div class="more-function" title="创建群聊" @click="createGroupDialog.show = true">
          <span class="iconfont icon-add-bold"></span>
        </div>
      </div>
      <div class="mt-4 w-full">
        <!-- 聊天会话列表 -->
        <conversation-list v-if="classifyActive === 'xxlb'" :conversationList="conversationList"
                           @openConversation="openConversation"/>
        <!-- 用户分组 -->
        <user-group ref="userGroup" v-else-if="classifyActive === 'yglb'" @selectContact="selectContact"/>
        <!-- 群组列表 -->
        <group-list v-else-if="classifyActive === 'qlb'" :group-list="groupList" @selectGroup="selectGroup" />
      </div>
    </div>
    <div class="message-box">
      <div class="masking"
           v-if="(openMessage.conversation.type === 'GROUP' && !openMessage.conversation.groupProfile.groupID) || (openMessage.conversation.type === 'C2C' && !openMessage.conversation.userProfile.userID)">
        您为打开任何会话
      </div>
      <div class="user-name">{{ openMessage.conversation | getShowName }}
      </div>
      <div class="message-list-box">
        <message-list :messageList="openMessage.messageList" :nextReqMessageID="openMessage.nextReqMessageID"
                      @loadMoreMessage="getMessageList(true)"/>
      </div>
      <div class="message-function-box">
        <span class="iconfont icon-smile-filling text-4xl mr-12 text-gray-400 relative cursor-pointer"
              @click="showEmotionSelector">
          <div class="emotion-box" v-if="emotionSelector">
            <keep-alive>
              <emotion @emotion="handleEmotion" :height="200"/>
            </keep-alive>
          </div>
        </span>
        <span class="iconfont icon-attachment text-4xl mr-12 text-gray-400 cursor-pointer" @click="selectFile">
          <input type="file" id="selectFileInput" class="hidden" @change="sendFileMessage">
        </span>
        <textarea
          class="message-input mr-12"
          v-model="openMessage.inputMessage" placeholder="请输入内容"
          @keydown="keyCodeForEvent"
          @keyup="keyCodeForEvent"
        />
        <button
          title="Ctrl+Enter快捷发送"
          class="send-btn flex items-center justify-center"
          :disabled="!openMessage.inputMessage || openMessage.messageSending" @click="sendTextMessage"
        >
          <p class="loading-rotate" v-if="openMessage.messageSending">
            <span class="iconfont icon-loading"/>
          </p>
          {{ openMessage.messageSending ? '发送中' : '发送' }}
        </button>
      </div>
    </div>
    <el-dialog
      title="创建群组"
      :visible.sync="createGroupDialog.show"
      width="800px">
      <create-group v-if="createGroupDialog.show" @closeDialog="createGroupDialog.show = false"/>
    </el-dialog>
  </div>
</template>

<script>
import { getUserInfo } from '@/api/public';
import { setToken } from '@/utils/auth';
import * as TimTool from './imTool';
import ConversationList from './components/conversationList'
import MessageList from './components/messageList'
import SearchPlugin from './components/searchPlugin'
import userGroup from './components/userGroup'
import Emotion from './components/Emotion/index'
import CreateGroup from './components/createGroup'
import GroupList from './components/groupList'

export default {
  name: 'im',
  components: {
    SearchPlugin,
    ConversationList,
    MessageList,
    userGroup,
    Emotion,
    CreateGroup,
    GroupList
  },
  data() {
    return {
      classifyList: [
        { label: '消息列表', value: 'xxlb' },
        { label: '员工列表', value: 'yglb' },
        { label: '群列表', value: 'qlb' }
      ],
      classifyActive: 'xxlb',
      conversationList: [],
      openMessage: {
        conversation: {
          userProfile: {}
        },
        messageList: [],
        nextReqMessageID: null,
        inputMessage: '',
        messageSending: false
      },
      shortcutKey: {
        ctrlAndEnter: {
          ctrl: false,
          enter: false
        }
      },
      emotionSelector: false,
      createGroupDialog: {
        show: false
      },
      groupList: [],
      info: {}
    }
  },
  mounted() {
    this.getToken()
    this.handGetUserInfo()
  },
  filters: {
    getShowName(conversation) {
      switch (conversation.type) {
        case 'GROUP': {
          return conversation.remark || conversation.groupProfile.name || conversation.userProfile.groupID
        }
        case 'C2C': {
          return conversation.remark || conversation.userProfile.nick || conversation.userProfile.userID
        }
      }
    }
  },
  methods: {
    
    showEmotionSelector() {
      this.emotionSelector = !this.emotionSelector
    },
    // 选择表情
    handleEmotion(data) {
      this.openMessage.inputMessage += data
    },
    // 监听键盘事件，主要用于监听发消息
    keyCodeForEvent(event) {
      console.log(event, 'event')
      if (event.type === 'keydown') {
        switch (event.key.toLowerCase()) {
          case 'control': {
            this.shortcutKey.ctrlAndEnter.ctrl = true
            break
          }
          case 'enter': {
            if (this.shortcutKey.ctrlAndEnter.ctrl) {
              this.shortcutKey.ctrlAndEnter.ctrl = false
              event.preventDefault()
              this.sendTextMessage()
            }
            break
          }
        }
      } else {
        switch (event.key.toLowerCase()) {
          case 'control': {
            this.shortcutKey.ctrlAndEnter.ctrl = false
            break
          }
        }
      }
    },
    // 选中联系人，打开对话
    selectContact(contact) {
      // 数据与IM统一
      this.openMessage.conversation = {
        conversationID: '',
        type: 'C2C',
        userProfile: {
          nick: contact.username,
          userID: contact.im_id,
          avatar: contact.avatar
        }
      }
      this.openMessage.messageList = []
    },
    // 选择群打开会话
    selectGroup(group) {
      // 数据与IM统一
      this.openMessage.conversation = {
        conversationID: '',
        type: 'GROUP',
        groupProfile: {
          name: group.name,
          groupID: group.groupID
        }
      }
      this.openMessage.messageList = []
    },
    // 获取会话列表
    getConversationList() {
      TimTool.getConversationList().then(imResponse => {
        this.conversationList = imResponse.data.conversationList
        console.log(this.conversationList, 'this.conversationList')
        if (this.conversationList.length) {
          this.openConversation(imResponse.data.conversationList[0])
        }
      })
    },
    // 打开会话
    openConversation(conversation) {
      this.openMessage.conversation = conversation
      this.openMessage.messageList = []
      this.getMessageList()
    },
    // 获取会话的消息列表
    getMessageList(loadMore = false) {
      TimTool.getMessageList({
        conversationID: this.openMessage.conversation.conversationID,
        nextReqMessageID: loadMore ? this.openMessage.nextReqMessageID : undefined,
        count: 15
      }).then(imResponse => {
        if (loadMore) {
          this.openMessage.messageList = imResponse.data.messageList.concat(this.openMessage.messageList)
        } else {
          this.openMessage.messageList = imResponse.data.messageList
        }
        this.openMessage.nextReqMessageID = imResponse.data.nextReqMessageID
      })
    },
    // 发送文本消息
    sendTextMessage() {
      if (!this.openMessage.inputMessage) {
        alert('不能发送空白消息！')
        return
      }
      if (this.openMessage.messageSending) {
        return
      }
      this.openMessage.messageSending = true
      const toId = this.openMessage.conversation.type === 'GROUP' ? this.openMessage.conversation.groupProfile.groupID : this.openMessage.conversation.userProfile.userID
      TimTool.sendMessage({
        toId: toId,
        message: this.openMessage.inputMessage,
        conversationType: this.openMessage.conversation.type
      }).then(imResponse => {
        this.openMessage.messageSending = false
        this.openMessage.inputMessage = ''
        this.openMessage.messageList.push(imResponse)
      })
    },
    selectFile() {
      document.getElementById('selectFileInput').click()
    },
    // 发送文件消息
    sendFileMessage(event) {
      const file = event.target.files[0]
      const toId = this.openMessage.conversation.type === 'GROUP' ? this.openMessage.conversation.groupProfile.groupID : this.openMessage.conversation.userProfile.userID
      TimTool.sendMessage({
        toId: toId,
        type: 'file',
        file: file
      }).then(imResponse => {
        this.openMessage.messageList.push(imResponse)
      })
    },
    // 搜索
    handleSearch(data) {
      this.$refs['userGroup'].handleSearch(data)
    },
    // 获取用户信息
    handGetUserInfo() {
      getUserInfo().then(res => {
        this.info = res.data
        // 登录IM
        TimTool.login({
          userID: res.data.im_id,
          userSig: res.data.sign
        }).then(() => {
          // 获取会话列表
          this.getConversationList()
          // 监听Im通知
          TimTool.eventListener(this.eventListener)
          TimTool.conversListener(this.eventListener)
        })
      }).catch(error => {
        console.log(error, 'error')
      })
    },
    // 获取token，用于调用后台接口获取IM账号等信息
    getToken() {
      const locationUrl = new URL(window.location)
      const paramsStr = locationUrl.search.split('?')[1]
      paramsStr.split('&').forEach(keyValueItem => {
        const keyValue = keyValueItem.split('=')
        if (keyValue[0] === 'token') {
          setToken(keyValue[1])
        }
      })
    },
    changeClassify(item) {
      
      this.classifyActive = item.value
      if (item.value === 'xxlb') {
        this.getConversationList()
      } else if (item.value === 'qlb') {
        this.getGroupList()
      }
    },
    // 获取群列表
    getGroupList() {
      TimTool.getGroupList().then(imResponse => {
        this.groupList = imResponse.data.groupList
      }).catch(imError => {
        console.log(imError, '获取群组列表出错')
      })
    },
    handleOpenMessage(item) {
      this.openMessage.id = item.id
    },
    // 监听事件
    eventListener(event) {
      console.log(event, event.name)
      switch (event.name) {
        case 'onMessageReceived': {
          const needUpdateMessage = event.data.find(message => {
            return this.openMessage.conversation.conversationID === message.conversationID
          })
          this.openMessage.messageList.push(needUpdateMessage)
          break
        }
      }

      // 获取会话列表
      this.getConversationList()
    },
  
  }
}
</script>

<style lang=scss>
.im {
  ::-webkit-scrollbar {
    width: 0;
    /*height: 4px;*/
  }

  .list-classify {
    background-color: #303841;
    width: 200px;
    height: 100vh;
    overflow-y: auto;
    /* padding-top: 80px; */

    .info{
      width: 100%;
      height: 60px;
      margin: 10px 0 20px 10px;
      display: flex;
      align-items: center;
     

      .avatar{
        width: 44px;
        height: 44px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .name{
        color: #eee;
      }
    }

    .classify-item {
      height: 60px;
      padding-left: 30px;
      display: flex;
      align-items: center;
      color: #eee;
      cursor: default;

      &:hover {
        background-color: rgba(#fff, 0.1);
      }
    }

    .classify-item-active {
      background-color: #363E47;
      color: #fff;
      border-left: 4px solid #55D48B;
    }
  }

  .list-classify-item {
    background-color: #363E47;
    width: 430px;
    height: 100vh;
    overflow-y: auto;
    flex-shrink: 0;
    border-left: 1px solid rgba(#67717c, 0.5);

    .search-title {
      height: 90px;
      border-bottom: 2px solid #303841;
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      top: 0;
      background-color: #363E47;
    }

    .more-function {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      margin-left: 30px;
      border-radius: 20px;
      background: #303841;
      cursor: pointer;

      > span {
        font-size: 20px;
        color: #A5B5C1;
      }
    }
  }

  .message-box {
    background-color: #F2F7F7;
    flex: auto;
    display: flex;
    flex-direction: column;
    position: relative;

    .masking {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#333, 0.2);
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #666;
      text-shadow: 0 0 3px #fff;
    }

    .user-name {
      height: 90px;
      line-height: 90px;
      font-size: 20px;
      color: #363E47;
      padding: 0 35px;
      border-bottom: 2px solid #E3E7E7;
    }

    .message-list-box {
      flex: auto;
      min-width: 500px;
      height: calc(100vh - 185px);

      .time-separator {
        color: #99A8B4;
        border-top: 1px solid #E3E7E7;
        text-align: center;
        position: relative;

        .time {
          background-color: #F2F7F7;
          padding: 0 40px;
          position: absolute;
          margin: 0 auto;
          font-size: 14px;
          height: 14px;
          line-height: 14px;
          top: -7px;
        }
      }
    }

    .message-function-box {
      height: 95px;
      background-color: #fff;
      box-shadow: 0 -1px 2px rgba(#333, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 50px;

      .message-input {
        flex: auto;
        background-color: #f8f8f8;
        height: 60px;
        outline: none;
        padding: 8px 10px;
        resize: none;
        color: #666;
      }

      .send-btn {
        width: 100px;
        height: 40px;
        background-color: #40d879;
        color: #fff;
        border-radius: 4px;

        &:hover {
          background-color: #20c65e;
        }
      }

      .emotion-box {
        position: absolute;
        width: 360px;
        top: -105px;
        left: 0;
      }
    }
  }
}
</style>
